import React, { useState } from 'react';
import ContentLayout from '../../components/content-layout';
import Tab from '../../components/tabs/Tab';
import CategoryCard from '../../components/cards/CategoryCard';
import Button from '../../components/button';
import { Routes } from '../../constants/routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetCategoriesList } from '../../services/queries/categories/useGetCategoriesQuery';
import { useGetAllQuery } from '../../hooks/query';
import { get, isEqual } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { LuPlus } from 'react-icons/lu';
import LinkButton from '../../components/button/LinkBtn';

function CategoriesScreen() {
  const { t } = useTranslation();
  const categoriesList = useGetCategoriesList();
  const categoriesEvents = categoriesList.data?.data || [];
  let [searchParams, setSearchParams] = useSearchParams();

  const { data, isLoading } = useGetAllQuery({
    key: 'category-merchant',
    url: 'admin/category/merchant'
  });

  const categoriesMerchants = get(data, 'data', []);

  let list = isEqual(searchParams.get('tab'), 'merchant') ? categoriesMerchants : categoriesEvents;
  return (
    <ContentLayout
      loader={categoriesList.isLoading || isLoading}
      rightAction={
        <LinkButton
          link={Routes.ADD_CATEGORY + `?tab=${searchParams.get('tab')}`}
          className="bg-main-green rounded-xl text-[16px] hover:bg-main-green-light px-4 py-2 flex gap-2 h-[48px] items-center justify-center w-[160px] shadow-md hover:bg-main-green-dark transition-all">
          <LuPlus fontSize={18} height={22} />
          {t('Добавить')}
        </LinkButton>
      }
      title={t('category')}>
      <Tab
        styles={{
          
        }}
        tabs={[
          { label: 'Event', value: 'event' },
          { label: 'Merchant', value: 'merchant' }
        ]}
      />

      <div className="grid grid-cols-4 gap-5 mt-10">
        {list.map((item) => (
          <div className="flex-1" key={item.id}>
            <Link to={`${Routes.CATEGORY}/${item.id}?tab=${searchParams.get('tab')}`}>
              <CategoryCard title={item.name.ru} img={item.icon} status={item.status} />
            </Link>
          </div>
        ))}
      </div>
    </ContentLayout>
  );
}

export default CategoriesScreen;
