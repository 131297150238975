import { Spinner, Button as TButton } from '@material-tailwind/react';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

function LinkButton({ children, link, loading, className, variant = 'filled', ...props }) {
  return (
    <Link to={link}>
      <TButton
        {...props}
        variant={variant}
        className={cx('', className)}>
        {loading ? <Spinner className="h-6 w-6" color="red" /> : children}
      </TButton>
    </Link>
  );
}

export default LinkButton;
