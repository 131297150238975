import { get, isEqual } from 'lodash';
import React, { useState } from 'react';
import useGetImages from '../../../../../hooks/useGetImages/useGetImages';
import elementCreate from '../../../../../utils/elementCreate';
import { Accordion, Box, Button, Flex, Menu, Select, Switch, Text } from '@mantine/core';
import { t } from 'i18next';

const ElementsList = ({ items, setActiveElement, activeElement, selectedElements = [], setSelectedElements }) => {
  const { base64Images } = useGetImages({ data: items });
  const [activeTab, setActiveTab] = useState(1);

  const handleInputChange = (index, field, value) => {
    const updatedElements = [...selectedElements];
    updatedElements[index] = {
      ...updatedElements[index],
      [field]: value,
    };
    setSelectedElements(updatedElements);
    
  };


  return (
    <div className="red-scrollbar w-[292px] flex flex-col gap-4 max-h-[80vh] overflow-auto">
      <Button.Group bg={'#111111'}>
        <Button
          onClick={() => setActiveTab(1)}
          fullWidth
          styles={{
            root: {
              border: 'none',
              borderRadius: isEqual(activeTab, 1) ? '12px' : '0',
            },
            label: {
              color: isEqual(activeTab, 1) ? '#C61F1F' : '#fff',
            },
          }}
          bg={isEqual(activeTab, 1) ? '#1D1D1D' : 'transparent'}
          variant="default">
          {t('Объекты')}
        </Button>
        <Button
          onClick={() => setActiveTab(2)}
          fullWidth
          styles={{
            root: {
              border: 'none',
              borderRadius: isEqual(activeTab, 2) ? '12px' : '0',
            },
            label: {
              color: isEqual(activeTab, 2) ? '#C61F1F' : '#fff',
            },
          }}
          bg={isEqual(activeTab, 2) ? '#1D1D1D' : 'transparent'}
          variant="default">
          {t('Список')}
        </Button>
      </Button.Group>

      {isEqual(activeTab, 1) ? (
        items.map((item, index) => {
          return (
            <div
              onClick={() => {
                setActiveElement(item);
              }}
              key={index}
              className={`w-full bg-[#111] flex items-center justify-center flex-col py-5 rounded-[10px] cursor-pointer border border-[transparent] ${isEqual(get(item, 'id'), get(activeElement, 'id')) && ' border-[red!important]'}`}>
              {get(item, 'figure', '').includes('api.dev.biletick.uz') ? (
                <img
                  width={120}
                  src={base64Images[get(item, 'figure', '')]}
                  alt={get(item, 'name.uz')}
                />
              ) : (
                <div className={elementCreate(get(item, 'figure'))} />
              )}

              <p className="mt-4">{get(item, 'name.uz')}</p>
              <span className="text-[12px]">
                {get(item, 'width')}X{get(item, 'height')}
              </span>
              <p className="mt-4">{get(item, 'type')}</p>
            </div>
          );
        })
      ) : (
        <Box>
          {selectedElements.map((item, index) => (
            <div
              onClick={() => {
                setActiveElement(item);
              }}
              key={index}
              className={`w-full bg-[#111] flex items-center justify-center flex-col py-5 rounded-[10px] cursor-pointer border border-[transparent] ${isEqual(get(item, 'id'), get(activeElement, 'id')) && ' border-[red!important]'}`}>
              {get(item, 'figure', '').includes('api.dev.biletick.uz') ? (
                <img
                  width={120}
                  src={base64Images[get(item, 'figure', '')]}
                  alt={get(item, 'name.uz')}
                />
              ) : (
                <div className={elementCreate(get(item, 'figure'))} />
              )}

              <p className="mt-4">{get(item, 'name.uz')}</p>
              <span className="text-[12px]">
                {get(item, 'width')}X{get(item, 'height')}
              </span>

              {!isEqual(get(item, 'type'), 'TEXT') ? (
                <Box mb={'12px'} display={"flex"} className="gap-2 p-3">
                  <div>
                    <Text pb={'6px'} size="14px" c="#777777">
                      {t('Ряд')}
                    </Text>
                    <input
                      name="row"
                      value={get(item, 'row', '')}
                      onChange={(e) => handleInputChange(index, 'row', e.target.value)}
                      placeholder=""
                      className={`border p-4 outline-none rounded-xl w-full bg-[#222222] border-transparent`}
                    />
                  </div>
                  <div>
                    <Text pb={'6px'} size="14px" c="#777777">
                      {t('Место')}
                    </Text>
                    <input
                      name="place"
                      value={get(item, 'place', '')}
                      onChange={(e) => handleInputChange(index, 'place', e.target.value)}
                      placeholder=""
                      className={`border p-4 outline-none rounded-xl w-full bg-[#222222] border-transparent`}
                    />
                  </div>
                </Box>
              ) : (
                <Box mb={'12px'}>
                  <Text pb={'6px'} size="14px" c="#777777">
                    {t('Место')}
                  </Text>
                  <input
                    name="place"
                    value={get(item, 'place', '')}
                    onChange={(e) => handleInputChange(index, 'place', e.target.value)}
                    placeholder=""
                    className={`border p-4 outline-none rounded-xl w-full bg-[#222222] border-transparent`}
                  />
                </Box>
              )}
            </div>
          ))}
        </Box>
      )}
    </div>
  );
};

export default ElementsList;
