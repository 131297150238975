import { forEach, get, isArray, isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { httpClient } from '../../services';
const postRequest = (url, attributes, config = {}, baseUrl) =>
  httpClient.post(url, attributes, config);
const usePostQuery = ({ hideSuccessToast = false, hideErrorToast = false, listKeyId = null }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error, isFetching } = useMutation(
    ({ url, attributes, config = {}, baseUrl = 'default' }) =>
      postRequest(url, attributes, config, baseUrl),
    {
      onSuccess: (data) => {
        if (!hideSuccessToast) {
          const message = get(data, 'data.message');
          // notifications.show({
          //     id: message,
          //     disallowClose: true,

          //     autoClose: 5000,
          //     title: t("SUCCESS"),
          //     message: t(message),
          //     color: "cyan",
          //     icon: <IconCheck />,
          // });
        }
        if (listKeyId) {
          if (isArray(listKeyId)) {
            forEach(listKeyId, (val) => {
              queryClient.invalidateQueries(val);
            });
          } else {
            queryClient.invalidateQueries(listKeyId);
          }
        }
      },
      onError: (data) => {
        let message = isArray(get(data, 'response.data'))
          ? get(data, 'response.data[0].message')
          : get(data, 'response.data.message') ?? '';
        message = isEmpty(message) || isNil(message) ? 'Something is wrong on server' : message;
        if (!hideErrorToast) {
          // notifications.show({
          //     id: message,
          //     disallowClose: true,
          //     autoClose: 5000,
          //     title: t("ERROR"),
          //     message: t(message),
          //     color: "red",
          //     icon: <IconX />,
          //     className: "my-notification-class",
          //     loading: false,
          //     position: "top-right",
          // });
        }
      }
    }
  );
  return {
    mutate,
    isLoading,
    isError,
    error,
    isFetching
  };
};
export default usePostQuery;
