import { IconButton } from '@material-tailwind/react';
import { get, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import Button from '../../components/button';
import ContentLayout from '../../components/content-layout';
import Table from '../../components/table';
import { Routes } from '../../constants/routes';
import { useDeleteQuery, useGetAllQuery } from '../../hooks/query';
import useGetImages from '../../hooks/useGetImages/useGetImages';
import elementCreate from '../../utils/elementCreate';
import StatusTag from '../../components/status/StatusTag';
import { Button as SButton } from '@mantine/core';
import moment from 'moment';
import { timeFormat } from '../../utils';

function EventsListContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllQuery({
    key: 'merchant',
    url: 'admin/merchant'
  });

  const lang = localStorage.getItem('i18nextLng');

  let { data: categories, isLoading: isLoadingCategories } = useGetAllQuery({
    key: 'category-merchant',
    url: 'admin/category/merchant'
  });
  categories = get(categories, 'data', []);

  const [activeTab, setActiveTab] = useState(categories[0]?.id);

  const elements = get(data, 'data', []);

  const { base64Images } = useGetImages({ data: elements, url: 'image.webLarge' });

  const content = [
    {
      header: t('Merchant-id'),
      key: 'Merchant-id',
      width: 200,
      renderCell: (row) => get(row, 'id')
    },
    {
      header: t('Картинка'),
      key: 'image',
      width: 200,
      renderCell: (row) => {
        const figureUrl = get(row, 'image.webLarge');

        if (figureUrl && figureUrl.includes('api.dev.biletick.uz')) {
          console.log(figureUrl);
          if (base64Images[figureUrl]) {
            return (
              <img width={60} src={base64Images[figureUrl]} alt={get(row, 'image.webLarge')} />
            );
          } else {
            return <div></div>;
          }
        }
        return <div className={elementCreate(figureUrl)} />;
      }
    },
    { header: t('Название'), key: 'title[uz]', renderCell: (row) => row.title?.uz },
    { header: t('Язык'), key: 'screenLanguage' },
    { header: t('Мерчант'), key: 'Мерчант' },
    {
      header: t('Status'),
      width: 120,
      key: 'status',
      renderCell: (row) => (
        <StatusTag
          value={get(row, 'status') ? 'active' : 'inactive'}
          variant={get(row, 'status') ? 'active' : 'inactive'}
        />
      )
    },
    {
      header: t('Дата создания'),
      key: 'createdDate',
      renderCell: (row) => moment(row?.createdDate).format(timeFormat)
    },

    {
      header: t('Actions'),
      key: '',
      renderCell: (row) => (
        <div>
          <IconButton variant="text" size="sm" onClick={() => handleDelete(get(row, 'id'))}>
            <RiDeleteBin6Fill color="red" size={24} />
          </IconButton>
        </div>
      )
    }
  ];

  const { mutate, isLoading: deleteLoading } = useDeleteQuery({
    listKeyId: 'merchant'
  });

  const handleDelete = (id) => {
    mutate(
      {
        url: '/admin/merchant/' + id
      },
      {
        onSuccess: ({ data }) => {},
        onError: () => {}
      }
    );
  };

  return (
    <>
      <ContentLayout
        title={t('Merchants')}
        loader={isLoading || deleteLoading}
        rightAction={
          <Button link={Routes.MERCHANT_CREATE} className="bg-accent-green">
            {t('create')}
          </Button>
        }>
        <SButton.Group my={'lg'} maw={'380px'} bg={'#111111'}>
          {categories.map((category, i) => (
            <SButton
              key={i}
              miw={'190px'}
              onClick={() => setActiveTab(category.id)}
              fullWidth
              styles={{
                root: {
                  border: 'none',
                  borderRadius: isEqual(category.id, activeTab) ? '12px' : '0'
                },
                label: {
                  color: isEqual(category.id, activeTab) ? '#C61F1F' : '#fff'
                }
              }}
              bg={isEqual(category.id, activeTab) ? '#1D1D1D' : '#111111'}
              variant="default">
              {t(get(get(category, 'name'), lang, ''))}
            </SButton>
          ))}
        </SButton.Group>
        <Table
          hasEdit={true}
          editUrl={Routes.MERCHANT_UPDATE}
          withOrder={true}
          content={content}
          list={elements}
        />
      </ContentLayout>
    </>
  );
}

export default EventsListContainer;
