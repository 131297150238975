import React, { useState } from 'react';
import ContentLayout from '../../../components/content-layout';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/table';
import { useGetAllQuery, usePostQuery } from '../../../hooks/query';
import { toast } from 'react-toastify';
import Button from '../../../components/button';
import { Modal } from '@mantine/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../../components/input/FormInput'; 
import Loader from '../../../components/loader';
import ToastSuccess from '../../../components/notification/SuccessNotification';

function AppLanguages() {
  const { t } = useTranslation();
  
  // Fetch the existing languages
  const { data, isLoading: isLoadingData } = useGetAllQuery({
    key: 'app-lang',
    url: `/admin/setting/app-lang`
  });
  
  const list = data?.data || [];
  const [openModal, setOpenModal] = useState(false);

  const { mutate, isLoading } = usePostQuery({
    listKeyId: 'app-lang' 
  });

  // Function to create a new language
  const createLang = (values) => {
    mutate(
      {
        url: '/admin/setting/app-lang',
        attributes: values
      },
      {
        onSuccess: () => {
          ToastSuccess(t('Key successfully created'));
          setOpenModal(false); // Close modal on success
        },
        onError: () => {
          toast.error(t('Failed to create key'));
        }
      }
    );
  };

  const content = [
    { header: t('key'), key: 'key' },
    { header: t('uz'), key: 'uz' },
    { header: t('ru'), key: 'ru' },
    { header: t('en'), key: 'en' }
  ];

  const validationSchema = Yup.object({
    key: Yup.string().required(t('Key is required')),
    uz: Yup.string().required(t('Uzbek value is required')),
    ru: Yup.string().required(t('Russian value is required')),
    en: Yup.string().required(t('English value is required'))
  });

  if (isLoading || isLoadingData) {
    return <Loader />;
  }

  return (
    <ContentLayout
      title={t('app_languages')}
      loader={isLoadingData}
      rightAction={
        <Button onClick={() => setOpenModal(true)} className="bg-accent-green">
          {t('create')}
        </Button>
      }
    >
      <Table withOrder={true} content={content} list={list} />

      <Modal
        opened={openModal}
        onClose={() => setOpenModal(false)}
        title={t('Create')}
      >
        <Formik
          initialValues={{
            key: '',
            uz: '',
            ru: '',
            en: ''
          }}
          validationSchema={validationSchema}
          onSubmit={createLang}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInput name="key" styles label={<span className='text-white'>{t('Key')}</span>} />
              <FormInput name="uz"  label={<span className='text-white'>{t('Uzbek')}</span>} />
              <FormInput name="ru" label={<span className='text-white'>{t('Russian')}</span>} />
              <FormInput name="en" label={<span className='text-white'>{t('English')}</span>} />

              <div className="flex justify-end mt-4">
                <Button onClick={() => setOpenModal(false)} className="bg-gray-500 mr-4">
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  className="bg-accent-green"
                  loading={isSubmitting || isLoading}
                >
                  {t('Create')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </ContentLayout>
  );
}

export default AppLanguages;
